import React from 'react'
import Img from 'gatsby-image'
import styles from './author.module.scss'

export default ({ fullName, jobPosition, photo }) => (
  <figure className={styles.author}>
    {photo.fixed ? (
      <Img
        className={styles['author__image']}
        fixed={photo.fixed}
        alt={`${fullName} ${jobPosition}`}
        style={{ width: '', height: '' }}
      />
    ) : (
      <div className={styles['author__image']}>
        <img src={photo.url} alt={`${fullName} ${jobPosition}`} />
      </div>
    )}
    <figcaption className={styles['author__content']}>
      <strong className="font-secondary">{fullName}</strong>
      {jobPosition}
    </figcaption>
  </figure>
)

import { graphql } from 'gatsby';
import React from 'react';

import Author from '../components/author';
import Blog from '../components/blog/index.js';
import { AsideGrid, ColumnsGrid, ContentGrid } from '../components/columnsGrid';
import ContentSection from '../components/contentSection';
import Division from '../components/division';
import IconText from '../components/iconText';
import Layout from '../components/layout';
import PageFooter from '../components/sectionFooter';
import Seo from '../components/seo';
import SubPageHeader from '../components/subPageHeader';
import Tags from '../components/tags';
import Text from '../components/text';
import IconCalendar from '../images/calendar.js';
import IconFacebook from '../images/facebook.js';
import ModularSectionCompany from '../modularSections/companies';
import ModularSectionContent from '../modularSections/content';
import ModularSectionCta from '../modularSections/cta';
import ModularSectionFaq from '../modularSections/faq';
import ModularSectionHeader from '../modularSections/header';
import ModularSectionMap from '../modularSections/map';
import ModularSectionPricing from '../modularSections/pricing';
import ModularSectionStory from '../modularSections/story';

export default ({ data, ...rest }) => {
  const {
    title,
    content,
    seo,
    textInCover,
    tags,
    url,
    sections,
    author,
    meta,
    featuredImage,
  } = data.datoCmsBlogPost

  const currentHref = rest.location.href

  const fbShare = event => {
    if (window.innerWidth > 959) {
      event.preventDefault()

      return window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${currentHref}`,
        'Facebook',
        'width=800,height=600'
      )
    }
  }

  return (
    <Layout>
      <Seo {...seo} pathname={rest.location.pathname} />
      <SubPageHeader
        title={title}
        text={textInCover}
        background={featuredImage}
      />

      <ContentSection>
        <ColumnsGrid>
          <ContentGrid>
            <Text content={content} />
          </ContentGrid>
          <AsideGrid>
            <Author {...author} />
            <div>
              <IconText icon={<IconCalendar />}>{meta.publishedAt}</IconText>
              <IconText icon={<IconFacebook />}>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${currentHref}`}
                  onClick={event => fbShare(event)}
                >
                  <strong>Share article</strong>
                </a>
              </IconText>
            </div>
            <Tags data={tags} />
          </AsideGrid>
        </ColumnsGrid>
      </ContentSection>

      {data.relatedBlogPosts && (
        <Division {...data.relatedArticlesDivision} gradient={true}>
          <Blog data={data.relatedBlogPosts.edges} />
        </Division>
      )}

      {sections.map((s, key) => {
        if (s.__typename === 'DatoCmsStoriesSection')
          return <ModularSectionStory key={key} {...s} />
        else if (s.__typename === 'DatoCmsFaqSection')
          return <ModularSectionFaq key={key} {...s} />
        else if (s.__typename === 'DatoCmsCompanyLogoListing')
          return <ModularSectionCompany key={key} {...s} />
        else if (s.__typename === 'DatoCmsCallToActionSection')
          return <ModularSectionCta key={key} {...s} />
        else if (s.__typename === 'DatoCmsMapSection')
          return <ModularSectionMap key={key} {...s} />
        else if (s.__typename === 'DatoCmsContentSection')
          return <ModularSectionContent key={key} {...s} />
        else if (s.__typename === 'DatoCmsPricingHeader')
          return <ModularSectionPricing key={key} {...s} url={url} />
        else if (s.__typename === 'DatoCmsHeaderCover')
          return (
            <ModularSectionHeader key={key} {...s} title={title} url={url} />
          )

        return (
          <div>
            {s.__typename} - This section is not supported yet. Please contact
            your developer.
          </div>
        )
      })}

      <PageFooter />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $tags: [String]!) {
    datoCmsBlogPost(id: { eq: $id }) {
      title
      url
      content
      textInCover
      meta {
        publishedAt(formatString: "MM. DD. YYYY")
      }
      seo {
        title
        description
        image {
          format
          size
          width
          height
          url
        }
      }
      featuredImage {
        fluid(maxWidth: 2560) {
          ...GatsbyDatoCmsFluid
        }
        alt
        url
      }
      tags {
        name
        url
      }
      author {
        fullName
        jobPosition
        photo {
          url
          fixed(width: 80, height: 80) {
            base64
            aspectRatio
            width
            height
            src
            srcSet
          }
        }
      }
      sections {
        ... on DatoCmsContentSection {
          text
        }

        ... on DatoCmsFaqSection {
          title
          faq {
            id
            question
            answer
          }
          button {
            text
            link
          }
          background {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
        }

        ... on DatoCmsStoriesSection {
          title
          stories {
            id
            story
            gymName
            personNameJobTitle
          }
          background {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            title
            url
          }
        }

        ... on DatoCmsCompanyLogoListing {
          darkBackgroundColor
          company {
            id
            companyName
            link
            logo {
              fixed(width: 300, height: 80) {
                ...GatsbyDatoCmsFixed
              }
              url
              format
            }
          }
        }

        ... on DatoCmsCallToActionSection {
          id
          cta {
            originalId
            title
            note
            buttonText
            buttonLink
            justifyContent
            isWhiteCta
            isLarge
            background {
              fluid(maxWidth: 2560) {
                ...GatsbyDatoCmsFluid
              }
              alt
              title
              url
            }
          }
        }
      }
    }

    relatedArticlesDivision: datoCmsDivision(
      id: { eq: "DatoCmsDivision-432155-en" }
    ) {
      title
      button {
        text
        link
      }
      background {
        fluid(maxWidth: 2560) {
          ...GatsbyDatoCmsFluid
        }
        alt
        url
      }
    }

    relatedBlogPosts: allDatoCmsBlogPost(
      filter: {
        tags: { elemMatch: { id: { in: $tags } } }
        id: { ne: $id }
        originalId: { ne: "969745" }
      }
      sort: { fields: meta___createdAt, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          originalId
          title
          url
          excerpt
          featuredImage {
            fluid(maxWidth: 720) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
          tags {
            url
          }
          treeParent {
            url
            treeParent {
              url
              treeParent {
                url
                treeParent {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
